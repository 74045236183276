<template>
  <b-modal
    :id="id"
    :title="title"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    @ok="$emit('handlerDownloadFileLog', dataLog)"
    @hidden="resetData"
  >
    <date-time-picker
      v-model="dataLog"
      :disabledInput="true"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BModal,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      dataLog: null,
    }
  },
  methods: {
    resetData() {
      this.dataLog = null
    },
  },
}
</script>

<style>

</style>
